import React from 'react'
import { BadgeList } from '../generic/BadgeList'

export function PostCategoryList({ categories }) {
  return (
    categories &&
    categories.length > 0 && (
      <BadgeList badges={categories} badgeClass="p-category" />
    )
  )
}
