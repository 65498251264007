import React from 'react'
import { graphql } from 'gatsby'

import { BylineAuthor } from '../generic/BylineAuthor'
import { BylineDate } from '../generic/BylineDate'
import { ItemEntry } from './ItemEntry'
import { PostCategoryList } from './PostCategoryList'

export function BlogItem({ item: post, isSingle }) {
  const title = post.title || post.slug.current

  const author = <BylineAuthor author={post.author} hidden={!isSingle} />

  const byline = (
    <>
      {isSingle && author}
      <BylineDate date={post.publishedAt} />
      {!isSingle && author}
    </>
  )

  return (
    <ItemEntry
      isSingle={isSingle}
      title={title}
      slug={'/blog/' + post.slug.current}
      byline={byline}
      excerpt={post.excerpt}
      content={post.body}
      afterContent={<PostCategoryList categories={post.categories} />}
    />
  )
}

export const query = graphql`
  fragment BlogItem on SanityPost {
    title
    slug {
      current
    }
    publishedAt
    author {
      name
      website
    }
    excerpt
    body: _rawBody(resolveReferences: { maxDepth: 2 })
    categories {
      title
    }
  }
`
