import React from 'react'
import styled from 'styled-components'

const StyledList = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.rhythm(1 / 4)};
  margin-bottom: ${({ theme }) => theme.rhythm(1 / 2)};
  margin-left: 0;
  flex-wrap: wrap;
`

const ListElement = styled.li`
  display: inline;
  margin-bottom: 0;
  ${({ theme }) => theme.scale(-0.2)}
  line-height: 1;
  background-color: #eeeeee;
  padding: ${({ theme }) => theme.rhythm(1 / 3)};
  border-radius: ${({ theme }) => theme.rhythm(1 / 2)};
`

export function BadgeList({ badges, badgeClass, className }) {
  return (
    <StyledList className={className}>
      {badges.map(badge => {
        if (badge && badge.title) {
          return (
            <ListElement key={badge.title} className={badgeClass}>
              {badge.title}
            </ListElement>
          )
        }
      })}
    </StyledList>
  )
}
