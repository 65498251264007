import React from 'react'
import { graphql } from 'gatsby'

import { Bio } from '../components/layout/Bio'
import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { ItemsList } from '../components/page/ItemsList'
import { BlogItem } from '../components/page/BlogItem'
import { H2 } from '../components/styled/H2'
import { PreviousNextPagination } from '../components/generic/PreviousNext'

function BlogPosts({
  data: {
    allPosts: { edges: posts },
  },
  pageContext: { currentPage, numPages },
}) {
  return (
    <Layout>
      <SEO
        title="All Blog Posts"
        currentPage={currentPage}
        numPages={numPages}
        image={`/og-images/blog/blog-page-${currentPage}.png`}
      />
      <Bio />
      <ItemsList
        header={<H2>All Blog Posts</H2>}
        items={posts}
        component={BlogItem}
      />
      <PreviousNextPagination
        currentPage={currentPage}
        numPages={numPages}
        linkPrefix="/blog/"
      />
    </Layout>
  )
}

export default BlogPosts

export const pageQuery = graphql`
  query PostsQuery($skip: Int!, $limit: Int!) {
    allPosts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          ...BlogItem
        }
      }
    }
  }
`
